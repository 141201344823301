import { apiphpClient } from "./configs/axiosConfig";

export const PatientRegister = async (data) => {
  let responseToBeSent;
  await apiphpClient
    .post(`patient/create`, data)
    .then((res) => {
      // console.log("res===>patient/create", res);
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const PatientLogin = async (data) => {
  let responseToBeSent;
  await apiphpClient
    .post(`patient/loginWithEmailPass`, data)
    .then((res) => {
      // console.log("res===>patient/Login", res);
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Login ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const Verify_otp = async (data) => {
  let responseToBeSent;
  await apiphpClient
    .post(`patient/check `, data)
    .then((res) => {
      responseToBeSent = res.data.messages;
    })
    .catch((err) => {
      console.log("Error in doc Login ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const PatLogout = async () => {
  let responseToBeSent;
  localStorage.removeItem("user");
  return responseToBeSent;
};
