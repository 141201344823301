import Footer from "./Footer";
import Header from "./Header";
import Logo from "../assets/logo.png";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetPharmaList, SendToPharmacy } from "../apis/UploadRxApis";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";

function PharmacyList() {
  const location = useLocation();
  const ipd_id = new URLSearchParams(location.search).get("ipd_id");
  const pres_id = new URLSearchParams(location.search).get("id");
  console.log(pres_id);
  const [data, setData] = useState([]);
  const [popup, setpopUp] = useState(false);

  // const data = [
  //   {
  //     pharmacy_name: "Pharmacy 1",
  //     name: "Doctor 1",
  //     surname: "Surname 1",
  //     image: Logo,
  //     local_address: "Address 1",
  //     latitude: 123.456,
  //     longitude: 789.012,
  //   },
  //   {
  //     pharmacy_name: "Pharmacy 2",
  //     name: "Doctor 2",
  //     surname: "Surname 2",
  //     image: Logo,
  //     local_address: "Address 2",
  //     latitude: 345.678,
  //     longitude: 901.234,
  //   },
  //   {
  //     pharmacy_name: "Pharmacy 3",
  //     name: "Doctor 3",
  //     surname: "Surname 3",
  //     image: Logo,
  //     local_address: "Address 3",
  //     latitude: 345.678,
  //     longitude: 901.234,
  //   },
  //   // Add more dummy data objects as needed
  // ];

  useEffect(() => {
    async function fetchData() {
      const user = JSON.parse(localStorage.getItem("user"));
      const data = {
        lat: await user?.latitude,
        lng: await user?.longitude,
      };

      const req = await GetPharmaList(data);
      // console.log(req);
      if (req?.response) {
        // const sortedData = cermaxAtTop(req?.response);
        setData(req?.response);
      }
    }
    fetchData();
  }, []);

  const handlePhoneLinkClick = (phone) => {
    window.location.href = `tel:${phone}`;
  };

  // const cermaxAtTop = (data) => {
  //   const lowestIdItem = data.reduce((min, item) =>
  //     item.id < min.id ? item : min
  //   );
  //   // const id213Item = data?.find((item) => item.id === 213);

  //   const restOfItems = data.filter((item) => item.id !== lowestIdItem.id);
  //   return [lowestIdItem, ...restOfItems];
  // };

  const SendRxToPharam = async (item) => {
    console.log(pres_id);
    const user = JSON.parse(localStorage.getItem("user"));
    const data = {
      ipd_id: ipd_id,
      pres_id: pres_id,
      pat_id: user?.id,
      phar_id: item?.staff_id,
    };
    try {
      const response = await SendToPharmacy(data);
      if (response?.insert_id) {
        setpopUp(true);
      }
      // console.log(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <Header />

      <div className="PharmacyContainer">
        <h1>Send Prescription For Billing</h1>
        <p className="fs-6">
          Select the nearest pharmacy to bill your prescription.
        </p>

        {popup ? (
          <div
            className="modal-overlay"
            onClick={() => setpopUp(false)}
            style={{ background: "transparent", width: "100vw" }}
          >
            <div className="modal-content">
              <Alert
                status="success"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                // height="200px"
                style={{ display: "flex", backgroundColor: "#C6F6D5" }}
              >
                {/* <CloseButton onClick={() => setpopUp(false)} /> */}
                <AlertIcon boxSize="40px" mr={0} color={"#2F855A"} />
                <AlertTitle mt={4} mb={1} fontSize="lg" textAlign={"center"}>
                  Prescription submitted!
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                  Thanks for submitting your Prescription. Our team will get
                  back to you soon.
                </AlertDescription>
              </Alert>
            </div>
          </div>
        ) : null}
        {data?.length > 0 &&
          data?.map((item, index) => (
            <div key={index}>
              <div>
                <img
                  src={item?.image === "" ? Logo : item.image}
                  className="img-fluid"
                  alt="UserImage"
                  style={{
                    height: "100px",
                    width: "100px",
                    objectFit: "contain",
                  }}
                />

                <div>
                  <h4>
                    {item?.pharmacy_name === "Change to your clinic name"
                      ? `${item?.name} ${item?.surname}`
                      : item?.pharmacy_name}
                    {/* {item?.title} */}
                  </h4>
                  <div>
                    <p>
                      <i className="fas fa-map-marker-alt me-1" />{" "}
                      <a
                        //  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
                        href={`https://www.google.com/maps/search/?api=1&query=${item?.latitude},${item?.longitude}`}
                        target="blank"
                      >
                        {item?.local_address}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <p onClick={() => handlePhoneLinkClick(9972065757)}>Call</p>
                <p onClick={() => SendRxToPharam(item)}>Send Rx</p>
              </div>
            </div>
          ))}
      </div>

      <Footer />
    </div>
  );
}

export default PharmacyList;
