import "./styles/App.scss";
import "./styles/MedicalBill.scss";
import "./styles/PharmacyList.scss";
import "./styles/RegistrationForm.scss";
import "./styles/Terms.scss";
import "./styles/contact.scss";
import "./styles/footer.scss";
import "./styles/header.scss";
import "./styles/home.scss";
import "./styles/mediaquery.scss";
import "./styles/upLoadRx.scss";
import Login from "./components/Login";
import MedicalBill from "./components/MedicalBill";
import PharmacyList from "./components/PharmacyList";
import RegistrationForm from "./components/Register";
import Terms from "./components/Terms";
import UploadRx from "./components/UploadRx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      {/* <Header /> */}
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}

        <Route
          exact
          path="/register/:pin/:d_id"
          element={<RegistrationForm />}
        />
        <Route exact path="/" element={<Login />} />
        <Route exact path="/uploadRx" element={<UploadRx />} />
        <Route exact path="/pharamcy" element={<PharmacyList />} />
        <Route exact path="/bills" element={<MedicalBill />} />
        <Route exact path="/terms" element={<Terms />} />

        {/* <Route path="/contact" element={<Contact />} /> */}
        {/* <Route path="/services" element={<Services />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
