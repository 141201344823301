import Header from "../components/Header";
import React, { useState } from "react";
import camera from "../assets/camera.svg";
import { useNavigate } from "react-router-dom";
import { PostIpdBasicApi, PostRxApi } from "../apis/UploadRxApis";

function UploadRx() {
  const navigate = useNavigate();

  // console.log(user, scanner);

  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedImage1, setSelectedImage1] = useState([]);

  const handelUploadRx = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage1((prevImg) => [...prevImg, reader.result]);
      };
      reader.readAsDataURL(file);
    }

    const files = event.target.files;
    setSelectedImage((prevFiles) => [...prevFiles, ...files]);
  };

  const handleRemove = (index) => {
    const updatedList = [...selectedImage];
    updatedList.splice(index, 1);
    setSelectedImage(updatedList);
    setSelectedImage1(updatedList);
  };

  // const PostRx = async () => {
  //   navigate({
  //     pathname: "/pharamcy",
  //     search: `?id=${1234}`,
  //   });
  // };

  const PostRx = async () => {
    const user = JSON.parse(localStorage.getItem("user")) || {};
    const scanner = JSON.parse(localStorage.getItem("scanner")) || {};
    // console.log(user, scanner);
    const data = {
      p_id: user.id,
      d_id: scanner.d_id,
    };
    // console.log(data);

    const req = await PostIpdBasicApi(data);
    // console.log(req);
    try {
      const formData = new FormData();
      formData.append("ipd_id", req?.inserted_id);

      selectedImage.forEach((file) => {
        formData.append(`imgUrl[]`, file);
      });

      const request = await PostRxApi(formData);

      // console.log(request);
      if (request?.status === "success") {
        navigate({
          pathname: "/pharamcy",
          search: `?id=${request?.insert_id}&ipd_id=${req?.inserted_id}`,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }

    // console.log("ipd_basic", req);
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#dee2e6",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Header />
      <div className="uploadRxConatiner">
        <h1>Click to upload prescriptions</h1>
        <p>Capture your prescription.</p>
        <form onSubmit={PostRx}>
          {selectedImage1?.length > 0 ? (
            // 44
            <div>
              {selectedImage1?.map((image, index) => (
                // 49
                <div key={index}>
                  {/* 58 */}
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      type="button"
                      aria-label="Close"
                      onClick={() => handleRemove(index)}
                    >
                      <span aria-hidden="true">X</span>
                    </button>
                    <div
                      style={{
                        display: "flex",
                        backgroundColor: "#20c997",
                        height: "20px",
                        width: "20px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p style={{ color: "white" }}>{index + 1}</p>
                    </div>
                  </div>
                  <img src={image} id="prof-avatar" alt="" />
                </div>
              ))}
              <div className="profile-pic-upload">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={camera}
                    id="prof-avatar"
                    alt=""
                    className="imageFluid"
                  />
                </div>
                <span>Upload Next</span>
                <input
                  type="file"
                  id="profile_image"
                  name="profile_image"
                  onChange={handelUploadRx}
                />
              </div>
            </div>
          ) : (
            <div className="profile-pic-upload">
              <div>
                <img
                  src={camera}
                  id="prof-avatar"
                  alt=""
                  className="imageFluid"
                />
              </div>
              <span>Upload Prescription</span>
              <input
                type="file"
                id="profile_image"
                name="profile_image"
                onChange={handelUploadRx}
              />
            </div>
          )}
        </form>
        {selectedImage?.length > 0 ? (
          <button
            //   type="submit"
            className="button-primary"
            onClick={() => PostRx()}
          >
            Send to Pharmacy{" "}
          </button>
        ) : null}
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default UploadRx;
