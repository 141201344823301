import React, { useState } from "react";
import registerImage from "../assets/feedback-six.png";
import { useNavigate } from "react-router-dom";
import { PatientLogin } from "./../apis/authApis";

const Login = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errText, setErrText] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    // console.log("Email:", phone, password, pin, d_id);
    const data = {
      mobileno: phone,
      password: password,
      pin: 560085,
    };
    if (phone === "") {
      setErrText("Phone must be specified");
    } else if (password === "") {
      setErrText("Password must be specified");
    } else {
      let req = await PatientLogin(data);
      // console.log("28==========>", req);
      if (req?.data?.id) {
        localStorage.setItem("user", JSON.stringify(req.data));
        navigate("/uploadRx");
      } else {
        setErrText("Error in Login");
      }
    }
  };

  return (
    <div className="regContainer">
      <h1>
        Cermax Pharmacy&nbsp;
        <span>Discounted Billing For Members</span>{" "}
      </h1>
      <p>Login with mobile number and password</p>
      <div>
        <img src={registerImage} alt="Graphics" />

        <form onSubmit={handleLogin}>
          <div>
            <label>
              Patient Phone<span>*</span>
            </label>
            <input
              type="text"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                setErrText("");
              }}
              required
            />
          </div>
          <div>
            <label>
              Patient password<span>*</span>
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrText("");
              }}
              required
            />
          </div>
          {errText?.length > 0 ? (
            <div style={{ color: "red", textAlign: "center" }}>
              <strong>Warning!</strong> {errText}
            </div>
          ) : null}

          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};
export default Login;
