import IMG01 from "../assets/Profile.svg";
import Logo from "../assets/logo.png";
import React from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Header = () => {
  const scanner = JSON.parse(localStorage.getItem("scanner")) || {};

  const Logout = () => {
    localStorage.removeItem("user");
  };

  return (
    <nav>
      <img src={Logo} id="prof-avatar" alt="" className="imageFluid" />

      <main>
        <Menu style={{ border: "none", boxShadow: "none" }}>
          <MenuButton border={"none"} backgroundColor={"white"}>
            <img src={IMG01} alt="User " style={{ height: 40, width: 40 }} />
            <ChevronDownIcon />
          </MenuButton>
          <MenuList>
            <MenuItem border={"none"} p={10} cursor={"pointer"}>
              <Link to="/bills">Your Bills</Link>
            </MenuItem>
            <MenuItem border={"none"} p={10} cursor={"pointer"}>
              <Link to="/uploadRx">Upload Prescription</Link>
            </MenuItem>
            <MenuItem border={"none"} p={10} cursor={"pointer"}>
              <Link
                to={`/register/${scanner.pin}/${scanner.d_id}`}
                onClick={() => Logout()}
              >
                Logout
              </Link>
            </MenuItem>
          </MenuList>
        </Menu>
      </main>
    </nav>
  );
};

export default Header;
