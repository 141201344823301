import axios from "axios";
import envConfig from "./envConfig";
export const apiClient = axios.create({
  //    withCredentials:false,
  baseURL: envConfig.baseUrl_node,
  headers: { "Content-Type": "multipart/form-data" },
});

export const apiphpClient = axios.create({
  baseURL: envConfig.baseUrl_php,
  headers: { "Content-Type": "multipart/form-data" },
});

export const apiphpWebClient = axios.create({
  baseURL: envConfig.baseUrl_php_web,
  headers: { "Content-Type": "multipart/form-data" },
});
