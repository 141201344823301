import Header from "./Header";
import IMG01 from "../assets/Profile.svg";
import React, { useEffect, useState } from "react";

// import { ViewIcon } from "@chakra-ui/icons";

import {
  // getParticularReport,
  getPharmacyBillDetails,
} from "./../apis/ReportsAndBills";

function MedicalBill() {
  // const dummyData = [
  //   {
  //     doctorName: "Dr. John Doe",
  //     amount: 1500,
  //     paymentStatus: "Paid",
  //   },
  //   {
  //     doctorName: "Dr. Jane Smith",
  //     amount: 2000,
  //     paymentStatus: "Pending",
  //   },
  //   {
  //     doctorName: "Dr. Michael Johnson",
  //     amount: 1800,
  //     paymentStatus: "Paid",
  //   },
  //   {
  //     doctorName: "Dr. Michael Johnson",
  //     amount: 1800,
  //     paymentStatus: "Paid",
  //   },
  //   {
  //     doctorName: "Dr. Michael Johnson",
  //     amount: 1800,
  //     paymentStatus: "Pending",
  //   },
  //   {
  //     doctorName: "Dr. Michael Johnson",
  //     amount: 1800,
  //     paymentStatus: "Paid",
  //   },
  // ];

  const user = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const data = {
        patient_id: user?.id,
      };

      const req = await getPharmacyBillDetails(data);
      // console.log(req);
      setData(req);
    }
    fetchData();
  }, []);

  // const getReport = (report_id) => {
  //   const data = {
  //     report_id: report_id,
  //   };
  //   const res = getParticularReport(data);
  //   console.log(res);
  // };

  return (
    <div>
      <Header />
      <div className="BillsContainer">
        <h1>Your prescription bills</h1>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Doctor</th>
                <th>Amount</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.length > 0
                ? data?.map((item, index) => (
                    <tr key={index}>
                      <td>#{index + 1}</td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            //   justifyContent: "center",
                          }}
                        >
                          <img
                            src={IMG01}
                            alt="User "
                            style={{ height: 50, width: 50 }}
                          />
                          &nbsp; &nbsp; <p>{item.doctor_name}</p>
                        </div>
                      </td>
                      <td>Rs. {item.net_amount}</td>
                      <td
                        style={{
                          color:
                            item.paid_amount === item.net_amount
                              ? "green"
                              : "red",
                        }}
                      >
                        {item.paid_amount === item.net_amount
                          ? "Paid"
                          : "Pending"}
                      </td>
                      {/* <td>
                        <div style={{ cursor: "pointer" }}>
                          <ViewIcon />{" "}
                          <p onClick={() => getReport(item?.id)}>View</p> &nbsp;
                        </div>
                      </td> */}
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
}

export default MedicalBill;
