import React, { useEffect, useState } from "react";
import registerImage from "../assets/feedback-six.png";
import { Checkbox, PinInput, PinInputField } from "@chakra-ui/react";
import { FaSquareCheck } from "react-icons/fa6";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { PatientLogin, PatientRegister, Verify_otp } from "./../apis/authApis";

const RegistrationForm = () => {
  const { pin, d_id } = useParams();
  const navigate = useNavigate(); // Extract pincode from URL path
  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [otpView, setOtpView] = useState(false);
  const [loginView, setLoginView] = useState(false);
  const [otp, setOtp] = useState();
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState(true);
  const [errText, setErrText] = useState("");
  useEffect(() => {
    let pinData = {
      pin: pin,
      d_id: d_id,
    };
    localStorage.setItem("scanner", JSON.stringify(pinData));
  });

  const handleRegistration = async (e) => {
    e.preventDefault();
    // console.log("Email:", name, surName, email, phone, pin, d_id);
    if (name === "" || surName === "" || phone === "" || !terms) {
      setErrText(
        `${
          name === ""
            ? "Name"
            : surName === ""
            ? "Surname"
            : phone === ""
            ? "Phone Number"
            : "terms and conditions"
        } is required`
      );
    } else if (phone.length < 10 || phone.length > 10) {
      setErrText("Invalid Phone Number");
    } else {
      const data = {
        patient_name: name,
        patient_lname: surName,
        mobileno: phone,
        pincode: pin,
      };

      let req = await PatientRegister(data);
      // console.log("======> res for register patients", req);
      if (req?.data?.id) {
        localStorage.setItem("user", JSON.stringify(req?.data));
        setOtpView(true);
      } else if (req?.mobileno) {
        setErrText("This Phone number is already used, please login");
      }
    }
  };
  const verifyOtp = async (e) => {
    e.preventDefault();
    // console.log("Email:", otp);
    const user = JSON.parse(localStorage.getItem("user"));
    if (otp === "") {
      setErrText("Otp cannot be empty");
    } else {
      const data = {
        id: user?.id,
        username: `pat${user?.id}`,
        otp: otp,
      };
      // console.log(data);
      let req = await Verify_otp(data);
      // console.log("verify OTP res", req);
      if (
        req?.success ===
          "Verification with username and mobilenumber is done,  Please set and do login with mpin" ||
        req?.success === "Successfully logged in, please set mpin for"
      ) {
        setErrText("");
        navigate("/uploadRx");
      }
    }
  };
  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    // console.log("Email:", phone, password, pin, d_id);
    const data = {
      mobileno: phone,
      password: password,
      pin: pin,
    };
    if (phone === "") {
      setErrText("Phone must be specified");
    } else if (password === "") {
      setErrText("Password must be specified");
    } else {
      let req = await PatientLogin(data);
      // console.log("28==========>", req);
      if (req?.data?.id) {
        localStorage.setItem("user", JSON.stringify(req.data));
        navigate("/uploadRx");
      } else {
        setErrText("Error in Login");
      }
    }
  };

  return (
    <div className="regContainer">
      <h1>
        Cermax Pharmacy&nbsp;<span>Discounted Billing For Members</span>
      </h1>
      <p>Authenticate your name and mobile number</p>
      <div>
        <img src={registerImage} alt="Graphics" />

        {!otpView && !loginView && (
          <form onSubmit={handleRegistration}>
            <div>
              <label>
                Patient Name <span>*</span>
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setErrText("");
                }}
                required
              />
            </div>
            <div>
              <label>
                Patient Surname<span>*</span>
              </label>
              <input
                type="text"
                value={surName}
                onChange={(e) => {
                  setSurName(e.target.value);
                  setErrText("");
                }}
                required
              />
            </div>
            <div>
              <label>
                Patient Phone<span>*</span>
              </label>
              <input
                type="text"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setErrText("");
                }}
                required
              />
            </div>
            <div>
              <label>
                Patient Email<span>*</span>
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrText("");
                }}
                required
              />
            </div>

            <Checkbox
              value={terms}
              onChange={() => {
                setTerms(!terms);
                setErrText("");
              }}
              colorScheme="green"
              borderWidth={1}
              borderColor="grey"
              iconColor="blue.400"
              iconSize="1rem"
              icon={terms ? <FaSquareCheck /> : <MdCheckBoxOutlineBlank />}
            >
              <Link to="/terms" style={{ textDecoration: "underline" }}>
                Terms and Conditions
              </Link>
            </Checkbox>
            {errText?.length > 0 ? (
              <div style={{ color: "red", textAlign: "center" }}>
                <strong>Warning!</strong> {errText}
              </div>
            ) : null}

            <button type="submit">Send Otp</button>
            <p onClick={() => setLoginView(true)}>Login</p>
          </form>
        )}

        {otpView && !loginView && (
          <form onSubmit={verifyOtp}>
            <div>
              <label>
                Enter OTP <span>*</span>
              </label>
              <div>
                <PinInput
                  otp
                  size="xs"
                  onChange={(value) => handleOtpChange(value)}
                  // value={pin}
                  // onChange={(e) => setPin(e.target.value)}
                >
                  <PinInputField
                    width={40}
                    height={40}
                    textAlign={"center"}
                    marginRight={5}
                    outline="1px solid #ccc"
                  />
                  <PinInputField
                    width={40}
                    height={40}
                    textAlign={"center"}
                    marginRight={5}
                    outline="1px solid #ccc"
                  />
                  <PinInputField
                    width={40}
                    height={40}
                    textAlign={"center"}
                    marginRight={5}
                    outline="1px solid #ccc"
                  />
                  <PinInputField
                    width={40}
                    height={40}
                    textAlign={"center"}
                    marginRight={5}
                    outline="1px solid #ccc"
                  />
                </PinInput>
              </div>
            </div>
            <button type="submit">Submit</button>
          </form>
        )}
        {loginView && (
          <form onSubmit={handleLogin}>
            <div>
              <label>
                Patient Phone<span>*</span>
              </label>
              <input
                type="text"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setErrText("");
                }}
                required
              />
            </div>
            <div>
              <label>
                Patient password<span>*</span>
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrText("");
                }}
                required
              />
            </div>
            {errText?.length > 0 ? (
              <div style={{ color: "red", textAlign: "center" }}>
                <strong>Warning!</strong> {errText}
              </div>
            ) : null}

            <button type="submit">Login</button>
            <p onClick={() => setLoginView(false)}>Register</p>
          </form>
        )}
      </div>
    </div>
  );
};

export default RegistrationForm;
